import React from "react";
import { navigate } from "gatsby";

import { URLS } from "@helpers/const";

type Props = {
  location?: {
    pathname: string;
  };
};

const Index: React.FC<Props> = ({ location }: Props) => {
  if (location.pathname === `/`) {
    if (typeof window !== "undefined") {
      navigate(URLS.home);
    }

    return null;
  }
};

export default Index;
